import { render, staticRenderFns } from "./default_reply.vue?vue&type=template&id=21e5fc12&scoped=true&"
import script from "./default_reply.vue?vue&type=script&lang=ts&"
export * from "./default_reply.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e5fc12",
  null
  
)

export default component.exports